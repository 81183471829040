import { Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import WhoWeServeComponent from "../components/WhoWeServeComponent";
import "./WhoWeServePage.css";

const WhoWeServerPage = () => {
  const whoWeServe = [
    {
      img: "value.jpg",
      title: "Individuals and Families",
      subTitle:
        "Planning for the life you want should begin as early as possible. Typically, a life event causes our clients to think about planning. This can be education, getting married, having a child, or preparing for retirement. No matter what stage of life you are in, you can trust that your Financial Professional will be there to provide the advice and guidance you need.",
    },
    {
      img: "mission.jpg",
      title: "Business Owners",
      subTitle:
        "Running a business or being an entrepreneur can be challenging emotionally, financially, and sometimes even physically. Our main priority is to ensure that your financial needs are covered so you can focus your time on what you’re good at, running your business. We can help by ensuring that your succession plan is congruent with your wishes, your tax benefits are maximized, risks are managed, and insurance coverage is in place for your employees.",
    },
    {
      img: "mission.jpg",
      title: "Executives",
      subTitle:
        "The stress, time, and devotion that executives put into running a company could leave little time for your personal financial planning. We help corporate leaders like yourself, build and follow a financial plan that could optimize your wealth. We take on a quarterback role in your financial life and work to minimize taxes, optimize your company equity options, manage cash flows needs and your charitable giving.",
    },
    {
      img: "mission.jpg",
      title: "Pre-Retirees and Retirees",
      subTitle:
        "Retirement can be scary, but by working with one of our Financial Professionals to develop a plan can obviate such trepidations. We start with the basics: what is your budget now and what will it be in retirement, how can you protect your money from being eroded by inflation, and how do you plan for the unexpected, such as needing care in retirement?",
    },
    {
      img: "mission.jpg",
      title: "NRIs and Seafarers",
      subTitle:
        "Handling your finances may be difficult for you while you are onboard and busy with you Chaos, we are here to manage and create wealth for you!",
    },
  ];
  return (
    <Fragment>
      <Link to="/">
        <Image
          src="images/earn.jpg"
          fluid
          style={{ height: "32rem", width: "100%", objectFit: "cover" }}
        />
      </Link>
      <Container className="whoWeServe-section pb-5" fluid>
        <Row>
          <Col>
            <h2 className="services-heading">We Serve</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center">
          {whoWeServe.map((serve) => (
            <Col md="auto">
              <WhoWeServeComponent service={serve} />
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};

export default WhoWeServerPage;
