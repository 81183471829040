import React, { useState, useEffect } from "react";
import axios from "axios";

const fetchUsers = async () => {
  const { data } = await axios.get("/api/users");
  return data;
};

const Products = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchUsers()
      .then((res) => setProducts(res))
      .catch((er) => console.log(er));
  }, []);
  return (
    <div>
      {products.map((item, idx) => (
        <p key={idx}>{item.name}</p>
      ))}
    </div>
  );
};

export default Products;
