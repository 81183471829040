import { Card } from "react-bootstrap";
import "./WhoWeServeComponent.css";

const WhoWeServeComponent = (props) => {
  return (
    <Card className="services-card md-auto">
      <Card.Img
        variant="top"
        src={`images/${props.service.img}`}
        className="services-img mx-auto w-50"
      />
      <Card.Body>
        <Card.Title className="services-card-title">
          {props.service.title}
        </Card.Title>
        <Card.Text className="services-card-content">
          {props.service.subTitle}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default WhoWeServeComponent;
