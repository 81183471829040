import "./App.css";
import { Routes, Route } from "react-router-dom";
// import DefaultPage from "./pages/DefaultPage";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";

import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import WhoWeServePage from "./pages/WhoWeServePage";
import ContactUsPage from "./pages/ContactUsPage";
import BlogsPage from "./pages/BlogsPage";
import Products from "./pages/Products";

function App() {
  document.title = "MaShruWealthCreation";
  return (
    <div className="App">
      <HeaderComponent />
      <main>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/who-we-serve" element={<WhoWeServePage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/products" element={<Products />} />
          </Routes>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}

export default App;
