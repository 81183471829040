import { Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./AboutUsPage.css";

const AboutUsPage = () => {
  return (
    <Fragment>
      <Link to="/">
        <Image
          src="images/earn.jpg"
          fluid
          style={{ height: "32rem", width: "100%", objectFit: "cover" }}
        />
      </Link>
      <Container className="about-container" fluid>
        <Row className="first-container">
          <Col>
            <p>
              You've worked hard to get where you are. And today, your financial
              needs may require more than traditional planning services.
            </p>
            <p>
              Our integrated wealth planning strategies are designed to
              coordinate all aspects of your financial life.
            </p>
            <p>
              Our planners can also provide help and guidance on subjects
              including taxes, Financial planning, insurance,and more. Get
              started by filling a form to protect and growing your wealth.
            </p>
            <p>
              <span className="about-content-title">Core Purpose :</span> To
              create wealth for our clients.
            </p>

            <p>
              <span className="about-content-title">Core Value :</span> Trust.
              Honesty. Integrity. We believe values matter, and we live by ours
              every day.
            </p>
          </Col>
          <Col>
            <Image
              src="images/coin-jar.jpg"
              className="about-content-img"
              rounded
              fluid
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Image
              src="images/coins.jpg"
              className="about-content-img"
              rounded
              fluid
            />
          </Col>
          <Col>
            <p>
              <span className="about-content-title">Our Mission :</span> To
              deliver a high quality personal wealth management service to a
              wide variety of clients, supported by a comprehensive range of
              services and investment solutions which reflect our core value.{" "}
            </p>
            <p>
              Working with our clients, which include individuals, NRIs, HNs,
              business owners and institutions, to provide customized solutions
              that meet their needs and financial goals.
            </p>
            <p>
              <span className="about-content-title">Our Vision :</span> To make
              our Clients Financially independent.
            </p>{" "}
            <p class="tag-line"> You invest your money, we multiply it!</p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AboutUsPage;
