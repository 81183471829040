import { Carousel } from "react-bootstrap";

const CarouselComponent = () => {
  return (
    <Carousel>
      <Carousel.Item interval={1000}>
        <img
          crossOrigin="anonymous"
          className="d-block w-100"
          src="/images/carousel/1.jpg"
          style={{ height: "42rem", objectFit: "cover" }}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>We are the Wealth creators</h3>
          <p>We'll help you create wealth to achieve your financial goals!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img
          crossOrigin="anonymous"
          className="d-block w-100"
          src="/images/carousel/earn.jpg"
          style={{ height: "42rem", objectFit: "cover" }}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>We are the Wealth creators</h3>
          <p>We'll help you create wealth to achieve your financial goals!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          crossOrigin="anonymous"
          className="d-block w-100"
          src="/images/carousel/invest.jpg"
          style={{ height: "42rem", objectFit: "cover" }}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>We are the Wealth creators</h3>
          <p>We'll help you create wealth to achieve your financial goals!</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
