import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomeAboutComponent.css";
const HomeAboutComponent = (props) => {
  return (
    <Card className="about-card md-auto">
      <Card.Img
        variant="top"
        className="about-img"
        src={`images/${props.about.img}`}
      />
      <Card.Body>
        <Card.Title className="about-card-title">
          {props.about.title}
        </Card.Title>
        <Card.Text>{props.about.subTitle}</Card.Text>
        <Button className="btn about-custom-btn" as={Link} to="/about-us">
          Learn More
        </Button>
      </Card.Body>
    </Card>
  );
};

export default HomeAboutComponent;
