import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";

import CarouselComponent from "../components/CarouselComponent";

import HomeServiceComponent from "../components/HomeServiceComponent";
import UserChatComponent from "../components/UserChatComponent";

import "./HomePage.css";

import HomeAboutComponent from "../components/HomeAboutComponent";

const HomePage = () => {
  const services = [
    {
      img: "families.jpg",
      title: "Individuals and Families",
      subTitle:
        "Planning for the life you want should begin as early as possible. Typically, a life event causes our clients to think about planning.",
    },
    {
      img: "business.jpg",
      title: "Business Owners",
      subTitle:
        "Running a business or being an entrepreneur can be challenging emotionally, financially, and sometimes even physically.",
    },
    {
      img: "executives.jpg",
      title: "Executives",
      subTitle:
        "The stress, time, and devotion that executives put into running a company could leave little time for your personal financial planning.",
    },
  ];

  const about = [
    {
      img: "value.jpg",
      title: "Core Value",
      subTitle:
        "Trust. Honesty. Integrity. We believe values matter, and we live by ourselves every day.",
    },
    {
      img: "mission.jpg",
      title: "Mission",
      subTitle:
        "To deliver a high quality personal wealth management service to a wide variety of clients.",
    },
  ];

  return (
    <Fragment>
      <CarouselComponent />
      <Container className="services-section pb-5" fluid>
        <h2 className="service-main-heading">How Can We Help You?</h2>
        <p className=" service-sub-heading">
          We offer a wide variety of services to achieve your financial goals.
        </p>
        <Row className="justify-content-md-center">
          {services.map((service) => (
            <Col md="auto">
              <HomeServiceComponent services={service} />
            </Col>
          ))}
        </Row>
        {/* <Button className="btn service-all-button" size="lg">
          Learn More
        </Button> */}
      </Container>
      <Container className="about-section pb-5" fluid>
        <Row className="justify-content-md-center text-center">
          {about.map((abt) => (
            <Col md="auto">
              <HomeAboutComponent about={abt} />
            </Col>
          ))}
        </Row>
      </Container>
      <UserChatComponent />
    </Fragment>
  );
};

export default HomePage;
