import { LinkContainer } from "react-router-bootstrap";
// import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./HeaderComponent.css";

const HeaderComponent = () => {
  // To fix header fixed-top
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-dark bg-gradient fixed-top"
      data-bs-theme="dark"
    >
      <Container>
        <Navbar.Brand href="#home">
          <LinkContainer to="/" style={{ height: "4rem", width: "10rem" }}>
            <img src="mashru-logo.png" alt="logo" />
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <LinkContainer to="/about-us">
              <Nav.Link className="me-5">About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/who-we-serve">
              <Nav.Link className="me-5">Who We Serve</Nav.Link>
            </LinkContainer>
            <LinkContainer className="me-5" to="/contact-us">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
            <LinkContainer className="me-5" to="/blogss">
              <Nav.Link>Blogs</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;
