import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./FooterComponent.css";

const FooterComponent = () => {
  return (
    <footer>
      <Container className="bg-dark text-white text-center py-5" fluid>
        <Row className="mb-3">
          <Col>
            <LinkContainer to="/">
              <img src="mashru-logo.png" alt="logo" />
            </LinkContainer>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Link
              to="https://www.youtube.com/@mashruwealthcreation"
              target="blank"
              className="me-4"
            >
              <i class="bi bi-youtube social-media-icon"></i>
            </Link>
            <Link
              to="https://www.instagram.com/finplanwith_shruti/?igsh=YW5yZWZwMWw0aGZr"
              target="blank"
              className="me-4"
            >
              <i class="bi bi-instagram social-media-icon"></i>
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100083678731270&mibextid=b06tZ0"
              target="blank"
            >
              <i class="bi bi-facebook social-media-icon"></i>
            </Link>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>Copyright &copy; 2023 MaShruWealthCreation</Col>
        </Row>
        <Row className="mb-2">
          <Col>invest@mashruwealthcreation.com</Col>
        </Row>
        <Row className="mb-2">
          <Col>Phone: +91 9167606797</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;
