import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomeServiceComponent.css";
const HomeServiceComponent = (props) => {
  return (
    <Card className="service-card">
      <Card.Img
        variant="top"
        className="service-img"
        src={`images/${props.services.img}`}
      />
      <Card.Body>
        <Card.Title className="service-card-title">
          {props.services.title}
        </Card.Title>
        <Card.Text>{props.services.subTitle}</Card.Text>
        <Button className="btn service-custom-btn" as={Link} to="/who-we-serve">
          Learn More
        </Button>
      </Card.Body>
    </Card>
  );
};

export default HomeServiceComponent;
